<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        allow-selection
        :columns="columns"
        :code="$route.path"
        :url-params="{ distributeType: '2' }"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <template #scoreLevel="{ text }">
          <span class="score-level">{{ scoreLevel[text] }}</span>
        </template>
        <template #distributionMethod="{ text }">
          <span v-if="text === 0">手动分发</span>
          <span v-if="text === 1">自动分发</span>
        </template>
        <template #resultType="{ text }">
          <span v-if="text === 1">无意愿</span>
          <span v-if="text === 2">已成交</span>
          <span v-if="text === 0">其他</span>
        </template>

        <template #resultConfirmStatus="{ text }">
          <span v-if="text === 0">待确认</span>
          <span v-if="text === 1">已确认</span>
        </template>
      </grid>
    </div>
    <a-modal
      title=""
      :visible="visible"
      :footer="null"
      centered
      width="580px"
      @cancel="visible = false"
    >
      <div class="iss-recording">
        <div>
          <div class="recordingTitle">跟进结果</div>
          <div v-if="form.resultList?.length !== 0">
            <div
              class="centent"
              v-for="item in form.resultList"
              :key="item.contactId"
            >
              <div class="doneTime">
                <div>完成时间：</div>
                <div>{{ item.followUpTime }}</div>
              </div>
              <div class="doneResults">
                <!--              todo  -->
                <div>结果类型：</div>
                <div v-if="item.followUpType === 1">无意愿</div>
                <div v-if="item.followUpType === 0">其他</div>
                <div v-if="item.followUpType === 2">已成交</div>
              </div>
              <div class="results">
                <div class="resultsTitle">跟进结果：</div>
                <div class="resultsContent">{{ item.followUpRecord }}</div>
              </div>
              <!--          图片需要做渲染   -->
              <div class="voucher">
                <div class="voucherTitle">凭证：</div>
                <div class="imgClass" v-if="item.followUpUrl !== ''">
                  <img
                    v-for="(imgItem, index) in item.followUpUrl?.split(',')"
                    :key="index"
                    :src="imgItem"
                    class="voucherImg"
                    @click="handleImg(imgItem)"
                  />
                </div>
                <div class="imgClass" v-else>无</div>
              </div>
            </div>
          </div>

          <div class="empty" v-else>暂无跟进结果</div>
        </div>

        <div class="recording">
          <div class="recordingTitle">跟进记录</div>
          <div v-if="form.recordList.length !== 0">
            <div
              class="recordingContent"
              v-for="item in form.recordList"
              :key="item.contactId"
            >
              <div class="recordingTime">{{ item.followUpTime }}</div>
              <div class="recordingText">{{ item.followUpRecord }}</div>
              <div class="recordingImage" v-if="item.followUpUrl !== ''">
                <img
                  v-for="(imgItem, index) in item.followUpUrl?.split(',')"
                  :key="index"
                  :src="imgItem"
                  class="voucherImg"
                  @click="handleImg(imgItem)"
                />
              </div>
              <div
                class="reply"
                v-if="item.marketingFollowUpReplyList?.length !== 0"
              >
                <div class="replyTitle">回复：</div>
                <div
                  class="replyContent"
                  v-for="replyItem in item.marketingFollowUpReplyList"
                  :key="replyItem.id"
                >
                  {{ replyItem.replyContent }}
                </div>
              </div>
            </div>
          </div>
          <div class="empty" v-else>暂无跟进记录</div>
        </div>
        <!--         -->
        <div
          class="iss-but"
          v-show="form.recordList.length !== 0 || form.resultList?.length !== 0"
        >
          <!--        todo 按钮需要拿到跟进结果的状态来判断展示   -->
          <a-button
            type="primary"
            v-if="resultConfirmStatus === 0"
            @click="handleFnBatch"
            >确认完成</a-button
          >
          <a-button type="primary" v-else disabled>已确认</a-button>
        </div>
      </div>
    </a-modal>
  </div>
  <!--  图片放大后的布局样式 -->
  <div class="img-preview" :class="{ show: showImage }">
    <div class="img-box" @click="showImage = false">
      <img :src="maxImage" alt="" />
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import distributionApi from '@/api/distribution';
import dictionaryApi from '@/api/dictionary.js';
import { Modal, Button, message } from 'ant-design-vue';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    AModal: Modal,
    // ARow: Row,
    // ACol: Col,
    AButton: Button,
    // ASpace: Space,
    // ATag: Tag,
  },
  setup() {
    const gridRef = ref();
    const state = reactive({
      showImage: false,
      maxImage: '',
      search: {},
      TagList: [],
      // 调试用
      visible: false,
      length: 0,
      // 商机确认结果状态
      resultConfirmStatus: null,
      gridSelectedRowKeys: [],
    });
    const form = reactive({
      // 跟进结果列表
      resultList: [],
      // 跟进记录列表
      recordList: [],
    });
    const handleImg = image => {
      console.log('image', image);
      // 拿到放大图片
      state.maxImage = image;
      state.showImage = true;
    };
    return {
      handleImg,
      gridRef,
      form,
      ...toRefs(state),
      scoreLevel: { high: '高分', middle: '中分', low: '低分' },
      items: [
        { key: 'contactName', label: '商机姓名' },
        { key: 'mobileNum', label: '手机' },
        { key: 'company', label: '公司' },
        {
          key: 'leadPoolId',
          label: '线索池',
          type: 'select',
          valueKey: 'leadPoolId',
          labelKey: 'leadPoolName',
          showSearch: true,
          filterOption: 'label',
          url: distributionApi.clueUrl,
        },
        // {
        //   key: 'industryLv1Code',
        //   label: '行业',
        //   type: 'select',
        //   valueKey: 'itemNameCn',
        //   labelKey: 'itemNameCn',
        //   showSearch: true,
        //   url: dictionaryApi.industryLv1CodeUrl,
        // },
        { key: 'industryLv1Code', label: '行业' },
        {
          key: 'city',
          label: '城市',
          type: 'select',
          valueKey: 'itemNameCn',
          labelKey: 'itemNameCn',
          showSearch: true,
          url: dictionaryApi.cityUrl,
        },

        {
          key: 'promoteUserId',
          label: '绑定人',
          type: 'select',
          valueKey: 'id',
          labelKey: 'userName',
          filterOption: 'label',
          showSearch: true,
          url: distributionApi.marketingUserListUrl,
        },
        {
          key: 'scoreLevel',
          label: '所属分阶',
          type: 'select',
          dataset: [
            { label: '全部', value: '' },
            { label: '高', value: 'high' },
            { label: '中', value: 'middle' },
          ],
        },
        {
          key: 'distributionMethod',
          label: '分发方式',
          type: 'select',
          dataset: [
            { label: '手动分发', value: '0' },
            { label: '自动分发', value: '1' },
          ],
        },
        {
          key: 'resultType',
          label: '结果类型',
          type: 'select',
          dataset: [
            { label: '无意愿', value: '1' },
            { label: '已成交', value: '2' },
            { label: '其他', value: '0' },
          ],
        },
        {
          key: 'resultConfirmStatus',
          label: '结果确认状态',
          type: 'select',
          dataset: [
            { label: '已确认', value: '1' },
            { label: '待确认', value: '0' },
          ],
        },
      ],
      columns: [
        {
          dataIndex: 'contactName',
          title: '商机姓名',
          width: 120,
          ellipsis: true,
        },
        { dataIndex: 'mobileNum', title: '手机', width: 140, ellipsis: true },
        { dataIndex: 'city', title: '城市', width: 120, ellipsis: true },
        { dataIndex: 'company', title: '公司', width: 180, ellipsis: true },
        {
          dataIndex: 'industryLv1Code',
          title: '行业',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'leadPoolName',
          title: '线索池',
          width: 200,
          ellipsis: true,
        },
        {
          dataIndex: 'scoreLevel',
          title: '所属分阶',
          width: 100,
          slots: { customRender: 'scoreLevel' },
        },
        { dataIndex: 'promoteUserName', title: '绑定人', width: 120 },
        {
          dataIndex: 'resultType',
          title: '结果类型',
          width: 100,
          slots: { customRender: 'resultType' },
        },
        {
          dataIndex: 'distributionMethod',
          title: '分发方式',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'distributionMethod' },
        },

        {
          dataIndex: 'resultConfirmStatus',
          title: '结果确认状态',
          width: 120,
          slots: { customRender: 'resultConfirmStatus' },
        },
        {
          key: 'id',
          fixed: 'right',
          title: '操作',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],

      btnOperation: [
        // {
        //   type: 'custom',
        //   label: '自定义分发',
        //   btnType: 'primary',
        //   permission: 'distribution:custom',
        //   fnClick: selectedRowKeys => {
        //     const length = selectedRowKeys.length;
        //     if (length) {
        //       state.length = length;
        //       state.gridSelectedRowKeys = selectedRowKeys;
        //       state.visible = true;
        //     } else {
        //       message.info('请先选择数据');
        //     }
        //   },
        // },
      ],
      options: [
        {
          type: 'view',
          label: '详情',
          icon: 'EyeTwoTone',
          permission: 'portraitApi:view',
          fnClick: record => {
            console.log('record', record);
            state.resultConfirmStatus = record.resultConfirmStatus;
            distributionApi
              .mgResult('', {
                contactId: record.contactId,
                promoteUserId: record.promoteUserId,
                source: record.leadPoolName,
              })
              .then(res => {
                form.resultList = res.resultList;
                form.recordList = res.recordList;
                // gridRef.value.refreshGrid();
                state.visible = true;
              });
          },
        },
      ],
      urlModal: distributionApi.marketingUserPageUrl,
      handleFnSearchModal: value => {
        const temp = Object.assign({}, value);
        state.searchModal = temp;
      },
      handleFnBatch: () => {
        distributionApi
          .marketingConfirm('', {
            id: form.recordList[0]?.guestId || form.resultList[0]?.guestId,
          })
          .then(() => {
            message.success('操作成功');
            state.visible = false;
            gridRef.value.refreshGrid();
          });
      },
      url: distributionApi.finishDistributePageUrl,
      gridHeight: document.body.clientHeight - 457,
      gridHeightModal: document.body.clientHeight - 416,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}
.iss-recording {
  max-height: 700px;
  overflow-y: auto;
}
.score-level {
  padding: 2px 8px;
  color: #1a46f9;
  border-radius: 2px;
  border: 1px solid #1a46f9;
}
.centent {
  box-shadow: @card-shadow;
}
.centent {
  box-shadow: 0px 3px 10px 0px #d6e2ff;
  padding: 20px;
  margin: 0px 10px 20px;
}
.empty {
  box-shadow: 0px 3px 10px 0px #d6e2ff;
  padding: 50px;
  text-align: center;
  margin: 0px 10px 10px;
}
.doneTime {
  display: flex;
}
.doneResults {
  display: flex;
  margin: 20px 0px;
}
.results {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  .resultsTitle {
    width: 16%;
  }
  .resultsContent {
    text-align: left;
    width: 84%;
  }
}
.voucher {
  display: flex;
  justify-content: space-between;
  .voucherTitle {
    width: 16%;
  }
}
.recordingTitle {
  text-align: center;
  margin: 20px 0px;
  font-size: 16px;
}
.recordingContent {
  box-shadow: @card-shadow;
  padding: 20px;
  margin: 0px 10px 20px;
}
.recordingText {
  margin: 20px 0px;
}
.iss-but {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.reply {
  background: #f2f2f2;
  padding: 10px;
}
.replyContent {
  text-indent: 24px;
}
// todo 设置图片大小
//
.imgClass {
  width: 448px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  .voucherImg {
    width: 25%;
    height: 65px;
    object-fit: contain;
    margin-bottom: 10px;
  }
}
.recordingImage {
  width: 490px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  .voucherImg {
    width: 25%;
    height: 65px;
    object-fit: contain;
    margin-bottom: 20px;
  }
}

.img-preview {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  background-color: #000000;
  z-index: 1001;
  top: 0;
  right: -110vw;
  transition: all 0.3s;
  &.show {
    right: 0;
  }
  .img-box {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    position: relative;
    cursor: pointer;
    img {
      height: 100vh;
      -webkit-user-drag: none;
      margin: auto;
    }
  }
}
</style>
